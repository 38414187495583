/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  color: #333333;
  font-family: "Quicksand", sans-serif;
}

app-loading {
  display: block;
  z-index: 10;
  width: 100%;
  position: absolute;
  top: 0;
}

.color-blue {
  color: #2196F3;
}

.color-pink {
  color: #FC47C7;
}

.color-orange {
  color: #FE753F;
}

.color-purple {
  color: #7695EA;
}

.color-green {
  color: #1CD299;
}

.color-red {
  color: #FC0030;
}

.color-light-grey {
  color: #C4C4C4;
}

.color-medium-grey {
  color: #9A9A9A;
}

.color-dark-grey {
  color: #7A7A7A;
}

.bg-dark-blue {
  background-color: #2196F3;
}

.bg-dark-pink {
  background-color: #FC47C7;
}

.bg-blue {
  background-color: #EEF8FF;
}

.bg-dark-blue {
  background-color: #2196F3;
}

.bg-pink {
  background-color: #FFF1F6;
}

.bg-orange {
  background-color: #FFF6F1;
}

.bg-purple {
  background-color: #E8EDF9;
}

.bg-green {
  background-color: #E1FFF5;
}

.bg-red {
  background-color: #FFF2F2;
}

.bg-light-grey {
  background-color: #C4C4C4;
}

.mat-dialog-container {
  background: transparent linear-gradient(119deg, #a8e0ff 0%, #f2e5ff 56%, #ebc3ff 100%) 0% 0% no-repeat padding-box !important;
  color: #333333 !important;
}

a,
.btn-link {
  color: #2196F3;
}

a:hover,
.btn-link:hover {
  color: #0089E5;
}

.hover-pointer:hover {
  cursor: pointer;
}

label {
  color: #7A7A7A;
  margin-bottom: 0.2em;
}

.btn-small-link {
  padding: 0.25em;
  font-size: 85%;
}

.fixed-top {
  z-index: 999;
}

.page-header {
  height: 70px;
  background-color: #ffffff30;
  padding: 0 1rem;
  border-radius: 0 0.5rem 0 0;
}
.page-header .heading {
  font-size: 1.5rem;
  line-height: 1;
  text-transform: capitalize;
}

.scrollable-content {
  height: calc(100vh - 100px);
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

/* width */
.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scrollable-content::-webkit-scrollbar-track {
  background: #ffffff30;
}

/* Handle */
.scrollable-content::-webkit-scrollbar-thumb {
  background: #9A9A9A;
  border-radius: 4.5rem;
}

/* Handle on hover */
.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #7A7A7A;
}

.btn-left-pane {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0.5rem 0;
  color: #9A9A9A;
}

.btn-left-pane:hover,
.btn-left-pane:focus {
  color: #333333;
  box-shadow: none;
}
.btn-left-pane:hover span,
.btn-left-pane:focus span {
  color: #2196F3;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2196F3;
}

.nav-link {
  color: #2196F3;
}

.nav-link:hover {
  color: #0089E5;
}

.invalid-feedback {
  position: absolute;
  bottom: -20px;
}

.p-button {
  padding: 0.375rem 1rem;
}

label {
  font-weight: bold;
  color: #333333;
}

.card {
  border: 0px;
  border-radius: 0.5rem;
  background-color: #ffffff90;
}

.form-check-input:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

.btn-primary {
  background-color: #2196F3;
  border-color: #2196F3;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #0089E5;
  border-color: #0089E5;
}

.btn-warning,
.btn-warning:hover,
.btn-warning:focus {
  color: #FFFFFF;
}

.table.table-custom {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}

.table.table-custom tbody {
  border-top: 0px;
}

.table.table-custom th,
.table.table-custom td {
  border-bottom: 0px;
  vertical-align: middle;
}

.table.table-custom tr > th {
  background: #ffffff;
}

.table.table-custom tr > th:first-child,
.table.table-custom tr > td:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}

.table.table-custom tr > th:last-child,
.table.table-custom tr > td:last-child {
  border-radius: 0 0.5rem 0.5rem 0;
}

.table.table-custom tr > td {
  background: #ffffff95;
}

body .p-component {
  color: #333333;
  font-family: "Quicksand", sans-serif;
}

body .p-datatable table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}

body .p-datatable .p-datatable-thead > tr > th:first-child,
body .p-datatable .p-datatable-tbody > tr > td:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}

body .p-datatable .p-datatable-thead > tr > th:last-child,
body .p-datatable .p-datatable-tbody > tr > td:last-child {
  border-radius: 0 0.5rem 0.5rem 0;
}

body .p-datatable .p-paginator-bottom {
  border-radius: 0.5rem;
  background: #ffffff95;
  color: #333333;
  font-size: 0.9rem;
  border-width: 0px;
}

body .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.75rem 0.5rem;
  border-width: 0px;
}

body .p-datatable .p-datatable-thead > tr > th {
  padding: 1rem 0.5rem;
  border-width: 0px;
  background: #FFFFFF;
  color: #333333;
  font-size: 0.9rem;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 0.8rem;
}

body .p-datatable .p-datatable-tbody > tr {
  background: #ffffff95;
  color: #333333;
  font-size: 0.9rem;
}

body .p-multiselect .p-multiselect-label {
  padding: 0.375rem 0.75rem;
}

@media (max-width: 991.98px) {
  .scrollable-content {
    height: calc(100vh - 165px);
  }
}