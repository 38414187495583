/* You can add global styles to this file, and also import other style files */
@import "./config.scss";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    color: $color_dark;
    font-family: 'Quicksand', sans-serif;
}

app-loading{
    display: block;
    z-index: 10;
    width: 100%;
    position: absolute;
    top: 0;
}

.color-blue {
    color: $color_blue;
}

.color-pink {
    color: $color_pink;
}

.color-orange {
    color: $color_orange;
}

.color-purple {
    color: $color_purple;
}

.color-green {
    color: $color_green;
}

.color-red {
    color: $color_red;
}

.color-light-grey {
    color: $color_light_grey;
}

.color-medium-grey {
    color: $color_medium_grey;
}

.color-dark-grey {
    color: $color_dark_grey;
}

.bg-dark-blue {
    background-color: $color_blue;
}

.bg-dark-pink {
    background-color: $color_pink;
}



.bg-blue {
    background-color: $color_light_blue;
}

.bg-dark-blue {
    background-color: $color_blue;
}

.bg-pink {
    background-color: $color_light_pink;
}

.bg-orange {
    background-color: $color_light_orange;
}

.bg-purple {
    background-color: $color_light_purple;
}

.bg-green {
    background-color: $color_light_green;
}

.bg-red {
    background-color: $color_light_red;
}

.bg-light-grey {
    background-color: $color_light_grey;
}

.mat-dialog-container {
    background: transparent linear-gradient(119deg, #a8e0ff 0%, #f2e5ff 56%, #ebc3ff 100%) 0% 0% no-repeat padding-box !important;

    color: $color_dark !important;
}

a,
.btn-link {
    color: $color_blue;
}

a:hover,
.btn-link:hover {
    color: $color_dark_blue;
}

.hover-pointer:hover{
    cursor: pointer;
}


label {
    color: $color_dark_grey;
    margin-bottom: 0.2em;
}

.btn-small-link {
    padding: 0.25em;
    font-size: 85%;
}

.fixed-top {
    z-index: 999;
}

.page-header {
    height: 70px;
    background-color: #ffffff30;
    padding: 0 1rem;
    border-radius: 0 0.5rem 0 0;

    .heading {
        font-size: 1.5rem;
        line-height: 1;
        text-transform: capitalize;
    }
}

.scrollable-content {
    height: calc(100vh - 100px);
    padding: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

/* width */
.scrollable-content::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.scrollable-content::-webkit-scrollbar-track {
    background: #ffffff30;
}

/* Handle */
.scrollable-content::-webkit-scrollbar-thumb {
    background: $color_medium_grey;
    border-radius: 4.5rem;
}

/* Handle on hover */
.scrollable-content::-webkit-scrollbar-thumb:hover {
    background: $color_dark_grey;
}

.btn-left-pane {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0.5rem 0;
    color: $color_medium_grey;
}

.btn-left-pane:hover,
.btn-left-pane:focus {
    color: $color_dark;

    span {
        color: $color_blue;
    }

    box-shadow: none;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: $color_blue;
}

.nav-link {
    color: $color_blue;
}

.nav-link:hover {
    color: $color_dark_blue;
}

.invalid-feedback {
    position: absolute;
    bottom: -20px;
}

.p-button {
    padding: 0.375rem 1rem;
}

label {
    font-weight: bold;
    color: $color_dark;
}

.card {
    border: 0px;
    border-radius: 0.5rem;
    background-color: #ffffff90;
}

.form-check-input:checked {
    background-color: $color_blue;
    border-color: $color_blue;
}

.btn-primary {
    background-color: $color_blue;
    border-color: $color_blue;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: $color_dark_blue;
    border-color: $color_dark_blue;
}

.btn-warning,
.btn-warning:hover,
.btn-warning:focus {
    color: $color_white;
}

.table.table-custom {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
}

.table.table-custom tbody {
    border-top: 0px;
}

.table.table-custom th,
.table.table-custom td {
    border-bottom: 0px;
    vertical-align: middle;
}

.table.table-custom tr>th {
    background: #ffffff;
}

.table.table-custom tr>th:first-child,
.table.table-custom tr>td:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
}

.table.table-custom tr>th:last-child,
.table.table-custom tr>td:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
}

.table.table-custom tr>td {
    background: #ffffff95;
}

// .mat-dialog-container{
//     background: transparent linear-gradient(119deg, #eaf5ff 0%, #fbf6fe 56%, #f8eaff 100%) 0% 0% no-repeat padding-box;
// }

body .p-component {
    color: $color_dark;
    font-family: 'Quicksand', sans-serif;
}

body .p-datatable table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
}

body .p-datatable .p-datatable-thead>tr>th:first-child,
body .p-datatable .p-datatable-tbody>tr>td:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
}

body .p-datatable .p-datatable-thead>tr>th:last-child,
body .p-datatable .p-datatable-tbody>tr>td:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
}

body .p-datatable .p-paginator-bottom {
    border-radius: 0.5rem;
    background: #ffffff95;
    color: $color_dark;
    font-size: 0.9rem;
    border-width: 0px;
}

body .p-datatable .p-datatable-tbody>tr>td {
    padding: 0.75rem 0.5rem;
    border-width: 0px;
}

body .p-datatable .p-datatable-thead>tr>th {
    padding: 1rem 0.5rem;
    border-width: 0px;
    background: $color_white;
    color: $color_dark;
    font-size: 0.9rem;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    font-size: 0.8rem;
}

body .p-datatable .p-datatable-tbody>tr {
    background: #ffffff95;
    color: $color_dark;
    font-size: 0.9rem;
}

body .p-multiselect .p-multiselect-label {
    padding: .375rem .75rem;
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .scrollable-content {
        height: calc(100vh - 165px);
    }

}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}