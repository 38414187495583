$color_blue: #2196F3;
$color_light_blue: #EEF8FF;
$color_dark_blue: #0089E5;

$color_pink: #FC47C7;
$color_light_pink: #FFF1F6;

$color_orange: #FE753F;
$color_light_orange: #FFF6F1;

$color_purple: #7695EA;
$color_light_purple: #E8EDF9;

$color_green: #1CD299;
$color_light_green: #E1FFF5;

$color_red : #FC0030;
$color_light_red: #FFF2F2;

$color_dark: #333333;
$color_dark_grey: #7A7A7A;
$color_medium_grey: #9A9A9A;
$color_light_grey: #C4C4C4;

$color_white: #FFFFFF;
$color_error_red: #dc3545;
$color_shadow: #00000029;